@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container {
  text-align: center;
  padding-top: $cds-spacing-100;

  & > div {

    color: var(--cds-color-text-subtle);
    @include cds-paragraph-xs;
  }
}