@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.container {
  font-size: 18px;
  background-color: transparent;

  :global {
    .ant-menu-title-content {
      padding: 0 4px;
      color: var(--cds-color-text-subtle);
    }

    .ant-menu-submenu-title {
      position: relative;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;

      & > svg {
        width: 20px;
        height: 16px;
      }

      &:after {
        content: '';
        display: block;
        margin-left: 5px;
        background-image: url('../../../../public/img/arrow-down-icon.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 12px;
        height: 4px;

        @media only screen and (min-width: 1025px) {
          bottom: 19px;
        }
      }
    }
  }
}