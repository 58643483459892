@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container {
  display: flex;
  padding: $cds-spacing-150 $cds-spacing-250;

  & > div {
    flex: 1;
  }

  & .menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
  }

  background: var(--cds-color-background-default);

  @media only screen and (min-width: 1025px) {
    background: var(--cds-color-background-deep);
  }
}

.headerMenuChild {
  color: var(--cds-color-text-subtle);
  @include cds-paragraph-m();
}

.mycircutorLink {
  @include cds-paragraph-m();
  margin-right: 0;
  align-self: center;
  text-align: left;

  color: var(--cds-color-text-subtle);
}