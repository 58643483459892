@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.container {
  padding: $cds-spacing-50;
  height: 100dvh;
  background: var(--cds-color-background-default);
  display: flex;
  flex-direction: column;
  overflow: auto;

  .main {
    min-height: calc(100dvh - $cds-spacing-50*2 - 87px);
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: $cds-spacing-150;
    padding: $cds-spacing-50 $cds-spacing-100;
    width: 100%;

    @media only screen and (min-width: 1025px) {
      padding: $cds-spacing-50 $cds-spacing-250;
    }
  }

  @media only screen and (min-width: 1025px) {
      height: 100vh;
      background: var(--cds-color-background-deep);
  }
}

.content {
  width: 100%;
  padding: 0 $cds-spacing-100;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}